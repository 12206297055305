import {
    PUBLIC_ENVIRONMENT,
    PUBLIC_SENTRY_DSN,
    PUBLIC_BASE_URL,
    PUBLIC_LONG_TASKS_BASE_URL,
    PUBLIC_ICEBERG_LONG_TASKS_BASE_URL,
} from '$env/static/public';
export const appConfig = {
    baseUrl: PUBLIC_BASE_URL,
    longTasksBaseUrl: PUBLIC_LONG_TASKS_BASE_URL,
    icebergLongTasksBaseUrl: PUBLIC_ICEBERG_LONG_TASKS_BASE_URL,
    kixiePowerlists: {
        linkedin: 151377,
        linkedinGuessing: 151378,
    },
    sentryDsn: PUBLIC_SENTRY_DSN,
    smartLeadCampaigns: {
        // Regular
        electricianPlaintext: 213443,
        mentalHealthTherapistPlaintext: 213142,
        radtechPlaintext: 211824,
        physicalTherapistPlaintext: 469044,
        // Guessing
        electricianGuessingContactPlaintext: 229199,
        mentalHealthTherapistGuessingContactPlaintext: 229950,
        radtechGuessingContactPlaintext: 229930,
        // Regular & Guessing
        journeymanPipefitterRegularAndGuessingPlaintext: 618734,
        journeymanPlumberRegularAndGuessingPlaintext: 627998,
        journeymanCarpenterRegularAndGuessingPlaintext: 628041,
        pipefitterRegularAndGuessingPlaintext: 628050,
        plumberRegularAndGuessingPlaintext: 631458,
        hvacTechnicianRegularAndGuessingPlaintext: 639058,
        welderRegularAndGuessingPlaintext: 639062,
        heavyEquipmentOperatorRegularAndGuessingPlaintext: 639083,
        carpenterRegularAndGuessingPlaintext: 639522,
        sheetMetalWorkerRegularAndGuessingPlaintext: 639539,
        cncMachinistRegularAndGuessingPlaintext: 639557,
    },
    environment: PUBLIC_ENVIRONMENT || 'development',
};
